/* form */
span.errorMsg {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}
input:invalid[focused="true"] {
  border: 1px solid red;
}
input:invalid[focused="true"]~span.errorMsg {
  display: block;
}

input.disabled {
  background-color: #efefef;
}


/* login */
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  background:#77CAF4;
}
.social-btn-container {
  max-width: 300px;
  margin: 0 auto;
}
.social-btn {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dadce0;
  color: #3c4043;
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  height: 40px;
}
.social-btn:hover {
  background: rgba(66,133,244,.04);
  border-color: #d2e3fc;
}
.social-btn .text {
  margin: 0 auto;
}
.social-btn img {
  height: 25px;
  width: 25px;
}
.login-logo {
  width: 120px;
  margin: 0 auto 20px;
  border-radius: 10px;
}
.download-apps-text {
  text-align: center;
}
.qrcode {
  width: auto;
  height: 50px;
  margin: 10px 5px 5px 5px;
  border-radius: 5px;
}
/* dashboard template */
.footer-bg {
  background: rgba(115, 117, 118, 0.5);
  color: #fff;
}
.backend-page {
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  height: auto;
  background: linear-gradient(rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)),
    url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
  background-size: cover;
  background-position: center;
}

/* profile */
.profile-pic {
  width: 250px;
  max-height: 250px;
  border-radius: 20%;
}

/* services */
.services-container {
  
}
.service-box {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #3652ee;
  border-radius: 10px;
}
.service-box input[type="checkbox"] {
  width: 30px;
  height: 30px;
  float: right;
}
.more-info-head {
  color: #3652ee;
  cursor: pointer;
}
.more-info {
  font-weight: normal;
  display: none;
  margin-bottom: 10px;
  color: black;
}
.more-info-head.active .more-info {
  display: block;
}
ul.service-left-menus {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
ul.service-left-menus li {
  font-size: 16px;
  line-height: 40px;
}
ul.service-left-menus li.active {
  color: #006aff;
  text-decoration: underline;
}
.selected-service {
  padding: 10px;
  background-color: #efefef;
  margin-bottom: 10px;
}
.customDatePickerWidth, 
.customDatePickerWidth .react-datepicker, 
.customDatePickerWidth .react-datepicker__month-container, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
.customDatePickerWidth .react-datepicker__day-name, 
.customDatePickerWidth .react-datepicker__day, 
.customDatePickerWidth .react-datepicker__time-name {
  width: 12%;
  line-height: 2.5em;
  font-size: 16px;
}
.react-calendar {
  width: 100%!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-calendar__tile--active {
  background-color: #157347!important;
}
.customDatePickerWidth .react-datepicker__day--keyboard-selected, 
.customDatePickerWidth .react-datepicker__month-text--keyboard-selected, 
.customDatePickerWidth .react-datepicker__quarter-text--keyboard-selected, 
.customDatePickerWidth .react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
}
button.activated::before {
  content: "";
  display: block;
  background: url("/public/icons8-tick-50.png") no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  float: left;
  margin: 0 6px 0 0;
}

.timezone-container span {
  color: #3652ee;
  cursor: pointer;
}

.selected-service-checkout {
  margin-bottom: 20px;
}
.price-right {
  float: right;
  font-size: 16px;
}

.loadingbox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(242, 245, 246, .95);
  text-align: center;
  z-index: 99999;
}
.loadingbox h3 {
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 10px;
}

.privacy-page h3 {
  margin: 20px 0 10px 0;
}
footer a {
  color: #000;
}

.login-form-container {
  background: white;
  margin: 20px;
  box-shadow: 0px 10px 20px #000000
}
.login-form-body {
  margin-bottom: 30px;
  border-left: 1px solid rgba(0, 0, 0, .17);
}
.login-form-body .card {
  border: none;
  padding:0 20px
}
.login-form-left {
  padding: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-form-left footer {
  background: transparent;
  color: #000;
}
.service-container {
  margin-bottom: 50px;
}
.service-container h1 {
  font-size: 18px;
}

/* mobile design */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
  .nav-link {
    padding-left: 20px;
  }


  .mytbl td, .mytbl tr { display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  .mytbl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .mytbl tr { border: 1px solid #77CAF4; }
  .mytbl tr + tr { margin-top: 1.5em; }
  .mytbl td {
    /* make like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 45%!important;
    text-align: left;
  }
  .mytbl td:before {
    content: attr(data-label) ':';
    line-height: 1.5;
    /* margin-left: -150%; */
    width: 42%;
    /* white-space: nowrap; */
    /* font-weight: bold; */
    display: inline-block;
    position: absolute;
    left: 10px;
  }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 993px) {

}

@media only screen and (max-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 100%;
      width: 100%;
  }
}

/* single row view */
.show-on-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .login-form-container {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .show-on-mobile {
    display: block;
  }
  .login-form-container {
    box-shadow: none;
    margin: 10px;
  }
}